// This file was generated by running 'ng generate @angular/material:m3-theme'.
// Proceed with caution if making changes to this file.

@use 'sass:map';
@use '@angular/material' as mat;


// Note: Color palettes are generated from primary: #282325
$_palettes: (
  primary: (
    0: #000000,
    10: rgb(16, 16, 16),
    20: #342f31,
    25: #403a3c,
    30: #4b4547,
    35: #575153,
    40: #635d5f,
    50: #7d7577,
    60: #978f91,
    70: #b2a9ab,
    80: #cec4c7,
    90: #eae0e2,
    95: #f9eef1,
    98: #fff8f8,
    99: #fffbff,
    100: #ffffff,
  ),
  secondary: (
    0: #000000,
    10: #1d1b1c,
    20: #333030,
    25: #3e3b3b,
    30: #494647,
    35: #555152,
    40: #615d5e,
    50: #7a7677,
    60: #958f90,
    70: #afaaab,
    80: #cbc5c6,
    90: #e8e1e2,
    95: #f6eff0,
    98: #fff8f8,
    99: #fffbff,
    100: #ffffff,
  ),
  tertiary: (
    0: #000000,
    10: #201a18,
    20: #362f2c,
    25: #413a37,
    30: #4d4542,
    35: #59514d,
    40: #655d59,
    50: #7e7571,
    60: #988f8a,
    70: #b4a9a5,
    80: #cfc4bf,
    90: #ece0db,
    95: #fbeee9,
    98: #fff8f6,
    99: #fffbff,
    100: #ffffff,
  ),
  neutral: (
    0: #000000,
    10: #1c1b1b,
    20: #313030,
    25: #3d3b3b,
    30: #484646,
    35: #545252,
    40: #605e5e,
    50: #797676,
    60: #939090,
    70: #aeaaaa,
    80: #cac5c5,
    90: #e6e1e1,
    95: #f4f0ef,
    98: #fdf8f8,
    99: #fffbff,
    100: #ffffff,
    4: #0f0e0e,
    6: #141313,
    12: #201f1f,
    17: #2b2a2a,
    22: #363434,
    24: #3a3939,
    87: #ddd9d9,
    92: #ece7e7,
    94: #f1edec,
    96: #f7f2f2,
  ),
  neutral-variant: (
    0: #000000,
    10: #201a1c,
    20: #352f31,
    25: #403a3c,
    30: #4c4548,
    35: #585053,
    40: #645c5f,
    50: #7d7578,
    60: #988e91,
    70: #b3a9ac,
    80: #cfc4c7,
    90: #ebe0e3,
    95: #faeef1,
    98: #fff8f8,
    99: #fffbff,
    100: #ffffff,
  ),
  error: (
    0: #000000,
    10: #410002,
    20: #690005,
    25: #7e0007,
    30: #93000a,
    35: #a80710,
    40: #ba1a1a,
    50: #de3730,
    60: #ff5449,
    70: #ff897d,
    80: #ffb4ab,
    90: #ffdad6,
    95: #ffedea,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  ),
);

$_palettes_light: (
  primary: (
    0: #ffffff,
    10: #fffbff,
    20: #fff8f8,
    25: #f9eef1,
    30: #eae0e2,
    35: #cec4c7,
    40: #b2a9ab,
    50: #978f91,
    60: #7d7577,
    70: #635d5f,
    80: #575153,
    90: #4b4547,
    95: #403a3c,
    98: #342f31,
    99: #1d1b1c,
    100: #000000,
  ),
  secondary: (
    0: #ffffff,
    10: #fffbff,
    20: #fff8f8,
    25: #f6eff0,
    30: #e8e1e2,
    35: #cbc5c6,
    40: #afaaab,
    50: #958f90,
    60: #7a7677,
    70: #615d5e,
    80: #555152,
    90: #494647,
    95: #3e3b3b,
    98: #333030,
    99: #1d1b1c,
    100: #000000,
  ),
  tertiary: (
    0: #ffffff,
    10: #fffbff,
    20: #fff8f6,
    25: #fbeee9,
    30: #ece0db,
    35: #cfc4bf,
    40: #b4a9a5,
    50: #988f8a,
    60: #7e7571,
    70: #655d59,
    80: #59514d,
    90: #4d4542,
    95: #413a37,
    98: #362f2c,
    99: #201a18,
    100: #000000,
  ),
  neutral: (
    0: #ffffff,
    10: #fffbff,
    20: #fdf8f8,
    25: #f4f0ef,
    30: #e6e1e1,
    35: #cac5c5,
    40: #aeaaaa,
    50: #939090,
    60: #797676,
    70: #605e5e,
    80: #545252,
    90: #484646,
    95: #3d3b3b,
    98: #313030,
    99: #1c1b1b,
    100: #000000,
    4: #0f0e0e,
    6: #141313,
    12: #201f1f,
    17: #2b2a2a,
    22: #363434,
    24: #3a3939,
    87: #ddd9d9,
    92: #ece7e7,
    94: #f1edec,
    96: #f7f2f2,
  ),
  neutral-variant: (
    0: #ffffff,
    10: #fffbff,
    20: #fff8f8,
    25: #faeef1,
    30: #ebe0e3,
    35: #cfc4c7,
    40: #7d7578,
    50: #645c5f,
    60: #585053,
    70: #4c4548,
    80: #403a3c,
    90: #352f31,
    95: #201a1c,
    98: #000000,
    99: #000000,
    100: #000000,
  ),
  error: (
    0: #ffffff,
    10: #fffbff,
    20: #fff8f7,
    25: #ffedea,
    30: #ffdad6,
    35: #ffb4ab,
    40: #ff897d,
    50: #ff5449,
    60: #ba1a1a,
    70: #a80710,
    80: #93000a,
    90: #7e0007,
    95: #690005,
    98: #410002,
    99: #000000,
    100: #000000,
  ),
);

$_rest: (
  secondary: map.get($_palettes, secondary),
  neutral: map.get($_palettes, neutral),
  neutral-variant: map.get($_palettes,  neutral-variant),
  error: map.get($_palettes, error),
);
$_primary: map.merge(map.get($_palettes, primary), $_rest);
$_secondary: map.merge(map.get($_palettes, secondary), $_rest);
$_tertiary: map.merge(map.get($_palettes, tertiary), $_rest);

$_rest_light: (
  secondary: map.get($_palettes_light, secondary),
  neutral: map.get($_palettes_light, neutral),
  neutral-variant: map.get($_palettes_light, neutral-variant),
  error: map.get($_palettes_light, error),
);
$_primary_light: map.merge(map.get($_palettes_light, primary), $_rest_light);
$_secondary_light: map.merge(map.get($_palettes_light, secondary), $_rest_light);
$_tertiary_light: map.merge(map.get($_palettes_light, tertiary), $_rest_light);

$light-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: $_tertiary_light,
    tertiary: $_primary_light,
    use-system-variables: false,
  ),
  typography: (
    use-system-variables: false,
  ),
));
$dark-theme: mat.define-theme((
  color: (
    theme-type: dark,
    primary: $_primary,
    tertiary: $_tertiary,
    use-system-variables: false,
  ),
  typography: (
    use-system-variables: false,
  ),
  density: (
    scale : -1
  )
));

// Light theme variables
$light-primary-color: map.get(map.get($_palettes_light, primary), 50);
$light-secondary-color: map.get(map.get($_palettes_light, secondary), 35);
$light-neutral-color: map.get(map.get($_palettes_light, neutral), 100);
$light-tertiary-color: map.get(map.get($_palettes_light, tertiary), 40);

// Dark theme variables
$dark-primary-color: map.get(map.get($_palettes, primary), 10);
$dark-secondary-color: map.get(map.get($_palettes, secondary), 20);
$dark-neutral-color: map.get(map.get($_palettes, neutral), 100);
$dark-tertiary-color: map.get(map.get($_palettes, tertiary), 40);

// Default dark theme
:root {
  --primary-color: #{$dark-primary-color};
  --secondary-color: #{$dark-secondary-color};
  --neutral-color: #{$dark-neutral-color};
  --tertiary-color: #{$dark-tertiary-color};
  --large-text-color: #BD8D5F;
  --focus-text-color: #BD8D5F;
  --regular-text-color: #E3CA57;
  --muted-text-color: #D9D9D9;
}

// For light theme
.light-theme {
  --primary-color: #{$light-primary-color};
  --secondary-color: #{$light-secondary-color};
  --neutral-color: #{$light-neutral-color};
  --tertiary-color: #{$light-tertiary-color};
}

.dark-theme {
  --primary-color: #{$dark-primary-color};
  --secondary-color: #{$dark-secondary-color};
  --neutral-color: #{$dark-neutral-color};
  --tertiary-color: #{$dark-tertiary-color};
}

html * {
  color: var(--regular-text-color);
}

html mat-icon{
  color: var(--regular-text-color);
}

html h1 h2 {
  color: var(--large-text-color);
}

html mat-label {
  color: var(--muted-text-color);
}

.large-text-color{
  color: var(--large-text-color) !important;
}

.focus-text-color{
  color: var(--focus-text-color) !important;
}

.regular-text-color{
  color: var(--regular-text-color) !important;
}

.muted-text-color{
  color: var(--muted-text-color) !important;
}

