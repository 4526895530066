/* You can add global styles to this file, and also import other style files */
//@import "../node_modules/@angular/material/prebuilt-themes/magenta-violet.css";

@use '@angular/material' as mat;
@use 'm3-theme.scss';

@include mat.core();


body {
  @include mat.all-component-themes(m3-theme.$dark-theme);
}

.light-theme body {
  @include mat.all-component-themes(m3-theme.$light-theme);
}


body {
  margin: 0;
  padding: 0;
  border: none;
  box-shadow: none;
}

/*
body * {
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out !important;
}
  */



html,
body {
  //@include mat.all-component-themes(m3-theme.$dark-theme);

  height: 100%;
  background-color: var(--secondary-color);
  //background-color: #282325;
  
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}


.collapse-panel {
  box-shadow: none !important;
}

.center-stuff {
  text-align: center;
  padding-top: 5px !important;
}

.remove-mat-expansion-panel-padding .mat-expansion-panel-content .mat-expansion-panel-body {
  padding: 0 !important;
  padding-top: 5px !important;
}

.profile-dropdown {
  text-align: center;
  padding: 20px;
  width: fit-content !important;
  max-width: fit-content !important;
  height: fit-content !important;
}

.profile-dropdown>* {
  width: 100% !important;
}

centered-dropdown {
  text-align: center;
  padding: 20px;
  max-width: fit-content !important;
  height: fit-content !important;
  max-height: calc(80vh - 100px) !important;
}


@media (orientation: portrait) {
  .centered-dropdown {
    max-height: 250px !important;
  }
}

@media (orientation: landscape) {
  .centered-dropdown {
    max-height: calc(100vh - 100px) !important;
  }
}

.centered-dropdown>* {
  width: 100% !important;
}

::ng-deep .growable-dropdown {
  width: fit-content !important;
  height: fit-content !important;
  max-height: calc(100vh - 100px);
}

@media (orientation: portrait) {
  .growable-dropdown {
    max-height: 250px !important;
  }
}
.growable-dropdown>* {
  //width: 110% !important;
}


.hover-icon:hover {
  cursor: pointer !important;
}

.pointer:hover {
  cursor: pointer !important;
}

.no-pointer:hover {
  cursor: auto !important;
}

.add-pointer-events {
  pointer-events: auto
}

.centered-dialog-content {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 100%;
}


.centered-dialog-content-vertical-top {
  margin-top: 10px;
  flex-grow: 1;
  overflow: auto;
  text-align: left !important;
}

.panel-class {
  animation: height .4s ease-in-out, width .4s ease-in-out, max-width .4s ease-in-out, max-height .4s ease-in-out !important;
  background-color: var(--primary-color) !important;
  border-color: red !important;
}

.mat-mdc-dialog-container {
  --mdc-dialog-container-shape: 0 !important;
  --mat-dialog-background-color:  var(--primary-color) !important;
  border-style: solid !important;
  border-width: 1px !important;
  //border-color: var(--regular-text-color) !important;
  //same as above with opacity
  border-color: rgba(227, 202, 87, 0.45) !important;
}

.dialog-title {
  //background-color: #560CB8 !important;
  background-color: var(--secondary-color);
  color: var(--large-text-color) !important;
  //padding: 16px !important;
  margin: 0 !important;
  display: flex !important;
  align-items: center !important;
}

.title-holder{
  margin-top: 10px !important;
  display: flex;
  gap: 0px;
  flex-direction: column;
  align-items: flex-start; 
  justify-content:flex-start;
}

.dialog-title h2 {
  color: var(--large-text-color) !important;
  padding: 0px !important;
  margin: 0px !important;
  font-size:calc(12px + 1.5vw) !important;
}
.dialog-title h3 {
  color: var(--large-text-color) !important;
  padding: 0px !important;
  margin: 0px !important;
  font-size:calc(12px + .1vw) !important;
}


.fade-img {
  opacity: 0;
  /* Initial opacity: hidden */
  transition: opacity 0.5s ease-in-out;
  /* Fade-in animation */
  will-change: opacity;
  /* Pre-optimize for opacity change */
}

.title-info-button {
  float: left;
  margin-left: 20px;
  margin-top: 0px;
  margin-bottom: 10px;
}

body {
  overscroll-behavior: none;
}

.dark-blurred-backdrop {
  backdrop-filter: blur(8px);  /* Adjust the blur effect as needed */
  background-color: rgba(0, 0, 0, 0.8);  /* Adjust the transparency and color for the dark overlay */
}

.tiled-background {
  background-image: url('./assets/logo-100_x_100.png');
  background-repeat: repeat;
  background-color: white;
}

@keyframes rainbowPulse {
  0% { background-color: red; }
  14.28% { background-color: orange; }
  28.56% { background-color: yellow; }
  42.84% { background-color: green; }
  57.12% { background-color: blue; }
  71.40% { background-color: indigo; }
  85.68% { background-color: violet; }
  100% { background-color: red; }
}

.pulsing-rainbow-tiled-background {
  animation: rainbowPulse 5s infinite;
  background-image: url('./assets/logo-100_x_100.png');
  background-repeat: repeat;
  background-color: white; /* Fallback background color */
}

.clear-backdrop{
  background-color: rgba(0, 0, 0, 0.0);
}

.overflow-dialog {
  overflow: visible !important;
}

.cdk-overlay-container {
  overflow: visible !important;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background: var(--secondary-color);
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: rgba(86, 12, 185, 0.1);
  border-radius: 10px;
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary-color) var(--primary-color);
}




////cool text stuff


.loading-text {
  font-size: 18px;
  font-weight: bold;
  background: linear-gradient(90deg, rgb(86, 12, 184) 0%, rgb(120, 80, 255) 40%, rgb(86, 12, 184) 100%);
  background-size: 200% 100%;
  -webkit-background-clip: text;
  color: transparent;
  animation: gradient-animation 3s linear infinite;
}

@keyframes gradient-animation {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}


.static-gradient-text {
  font-size: 18px;
  font-weight: bold;
  background: linear-gradient(90deg, rgb(86, 12, 184) 0%, rgb(120, 80, 255) 40%, rgb(86, 12, 184) 100%);
  background-size: 100% 100%;
  -webkit-background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent !important;
}

.subtle-gradient-text {
  //font-size: 18px;
  //font-weight: bold;
  /*
  background: linear-gradient(
    90deg,
    rgb(176 139 231) 0%,
    rgb(186 158 242) 40%,
    rgb(172 147 208) 100%
  );
  background-size: 200% 100%;
  -webkit-background-clip: text;
  color: transparent;
  animation: gradient-animation 4s linear infinite;
  -webkit-text-fill-color: transparent !important;
  */
  color: var(--regular-text-color)
}

@keyframes gradient-animation {
  0% {
    background-position: 200% 0%;
  }
  100% {
    background-position: -200% 0%;
  }
}


@import 'ngx-sharebuttons/themes/default';



.mat-mdc-snack-bar-container {
    --mdc-snackbar-container-color:  #1D1A1D;
    --mat-mdc-snack-bar-button-color: #560CB8;
    --mdc-snackbar-supporting-text-color: white;
    --mat-mdc-snack-bar-container: #1D1A1D;
    color: white;
}


.mat-mdc-snack-bar-container * {
 font-size: 16px !important;
 font-weight: 500 !important;
 color: var(--regular-text-color) !important;
}

h1 {
  all: unset;
  display: inline;
  font: inherit;
  font-size: 14px !important;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
  color: inherit;
  line-height: inherit;
}

.cdk-overlay-container {
  z-index: 99999999999999; 
}

:root {
  --snackbar-top: 140px; // Default value
}

.custom-snackbar {
  margin: 0 !important;
  position: absolute;
  right: 25px;
  top: var(--snackbar-top);
  max-width: 88vw;
  padding: 4px 6px;
  color: var(--regular-text-color) !important;
  font-size: 14px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
  transition: top 0.3s ease, opacity 0.3s ease !important;
  opacity: 1;
  z-index: 1000;
  background-color: var(--regular-text-color);; 
}

.fade-in {
  opacity: 0;
  animation: fadeIn .25s ease-in-out forwards !important; 
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


.tooltip{
  background-color: #560CB8 !important;
  color: white !important;
}

.add-svg-pointer-events{
  pointer-events: fill;
}

.loading-icon {
  animation: rotateWave 4s infinite cubic-bezier(0.68, -0.55, 0.27, 1.55);
  display: inline-block;
}

@keyframes rotateWave {
  0% {
    transform: rotate(0deg) scale(1);
    opacity: 0.8;
  }
  25% {
    transform: rotate(90deg) scale(1.1);
    opacity: 0.9;
  }
  50% {
    transform: rotate(180deg) scale(0.9);
    opacity: 0.6;
  }
  75% {
    transform: rotate(270deg) scale(1.2);
    opacity: 1;
  }
  100% {
    transform: rotate(360deg) scale(1);
    opacity: 0.8;
  }
}